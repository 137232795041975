import module from 'module';

const templateUrl = require('./corporate-data.template.html');
module.component('customerProfileCorporateData', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '=',
    'onNameChange': '&',
    'onWatchlistVerify': '&',
    'editMode': '='
  },
  controller: function ($scope, $timeout, dict, propertyConfigService) {
    let that = this;

    that.dict = dict;
    that.cfg = propertyConfigService;

    that.nameChanged = () => {
      that.onNameChange();
      that.verifyDone = false;
    }

    that.watchlistVerify = async (e) => {
      e.preventDefault();

      that.onWatchlistVerify({
        input: {
          customerId: that.profile.id,
          customerType: that.profile.customerType,
          name1: that.profile.corporateData.businessName,
        }
      });

      that.verifyDone = true;
    }
  }
});
